
<div class="active-filter">

  <div class="title">
    <span>{{ filter.featureConst | LayerNameFormatPipe }}</span>
    <button (click)="emitRemoveFilter()" class="remove-button">
      <mat-icon svgIcon="trash"></mat-icon>
    </button>
  </div>

  <div *ngIf="filter.accessibleOnLevels && filter.accessibleOnLevels?.includes(activeLevel!) || !filter.accessibleOnLevels; else notAvailableOnLevel">
    <mat-slider *ngIf="isNumber(filter.max! + filter.min!)"
                [step]="filter.featureConst | sliderValues:filter:STEP"
                [min]="filter.featureConst | sliderValues:filter:MIN_LIMIT"
                [max]="filter.featureConst | sliderValues:filter:MAX_LIMIT"
                style="width: 100%;"
    >
      <input [value]="filter.min" matSliderStartThumb
             (valueChange)="updateFilter(filter, $event, true)">
      <input [value]="filter.max" matSliderEndThumb
             (valueChange)="updateFilter(filter, $event, false)">
    </mat-slider>
    <div class="slider-labels">
      <label *ngIf="filter.min && isFinite(filter.min)">{{ filter.min | numberFormatter:2:filter.featureConst }}</label>
      <label *ngIf="filter.max && isFinite(filter.max)">{{ filter.max | numberFormatter:2:filter.featureConst }}</label>
    </div>
  </div>


</div>

<ng-template #notAvailableOnLevel>
  <span *ngIf="filter.disclaimer">{{filter.disclaimer?.text}}</span>
</ng-template>
