import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNumber } from '@turf/helpers';
import { FeatureFilter, FiltersMenuService } from '../filters-menu.service';
import { MAX_LIMIT, MIN_LIMIT, STEP } from './slider-values';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input() filter!: FeatureFilter;
  @Input() activeLevel!: string;
  @Output() removeFilter = new EventEmitter();

  constructor(public filtersService: FiltersMenuService) {}

  ngOnInit() {}

  public updateFilter(
    filter: FeatureFilter,
    value: number,
    isMin: boolean
  ): void {
    isMin ? (filter.min = value) : (filter.max = value);

    this.filtersService.updateFilters();
  }

  public emitRemoveFilter(): void {
    this.removeFilter.emit();
  }

  protected readonly isNumber = isNumber;
  protected readonly isFinite = isFinite;
  protected readonly Math = Math;
  protected readonly STEP = STEP;
  protected readonly MIN_LIMIT = MIN_LIMIT;
  protected readonly MAX_LIMIT = MAX_LIMIT;
}
