import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapboxComponent } from './mapbox/mapbox.component';
import { MapMenuComponent } from './menu/right-menu/map-menu/map-menu.component';
import { LayersMenuComponent } from './menu/right-menu/layers-menu/layers-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PlotlySharedModule, PlotlyViaCDNModule } from 'angular-plotly.js';
import { GoogleMapLinkComponent } from './google-map-link/google-map-link.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MapComponent } from './map.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { ColorScaleComponent } from './mapbox/visualization/colorscale/color-scale.component';
import { MatTreeModule } from '@angular/material/tree';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SharedModule } from '../shared/shared.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { PopupHintComponent } from './popup-hint/popup-hint.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { SelectionToolComponent } from './mapbox/visualization/selection-tool/selection-tool.component';
import { LevelSelectionComponent } from './mapbox/visualization/level-selection/level-selection.component';
import { RouterModule, Routes } from '@angular/router';
import { ChartLegendGapFixDirective } from './menu/right-menu/layers-menu/chart-legend-gap-fix.directive';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MapHttpService } from './mapbox/services/map-http.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoadingInterceptor } from '../shared/api/loading.interceptor';
import { ChartYearSelectionComponent } from './menu/right-menu/layers-menu/shared/chart-year-selection/chart-year-selection.component';
import { ChartWarningComponent } from './menu/right-menu/layers-menu/shared/chart-warning/chart-warning.component';
import { PlotlyChartComponent } from './menu/right-menu/layers-menu/shared/plotly-chart/plotly-chart.component';
import { SpecialHealthCareComponent } from './menu/right-menu/layers-menu/upcoming-group/upcoming-group.component';
import { CommonColorscaleComponent } from './common-colorscale/common-colorscale.component';
import { LayerNameFormatPipe } from './common-colorscale/layer-name-format.pipe';
import { FeatureTooltipComponent } from './menu/right-menu/layers-menu/shared/feature-tooltip/feature-tooltip.component';
import { IsFeatureAccessiblePipe } from './menu/right-menu/layers-menu/shared/is-feature-accessible.pipe';
import { IsGroupAccessiblePipe } from './menu/right-menu/layers-menu/shared/is-group-accessible.pipe';
import { MenuGroupComponent } from './menu/right-menu/layers-menu/menu-group/menu-group.component';
import { ChartBlockComponent } from './menu/right-menu/layers-menu/shared/chart-block/chart-block.component';
import { GroupTitleFormatterPipe } from './menu/right-menu/layers-menu/menu-group/group-title-formatter.pipe';
import { FeatureDisclaimerComponent } from './menu/right-menu/layers-menu/shared/feature-disclaimer/feature-disclaimer.component';
import { MapSearchComponent } from './map-search/map-search.component';
import { MapPopupComponent } from './mapbox/visualization/map-popup/map-popup.component';
import { FormatFeaturePipe } from './mapbox/visualization/map-popup/format-feature.pipe';
import { LevelsPopupListComponent } from './mapbox/visualization/map-popup/layers-popup-list/levels-popup-list.component';
import { SchoolsPopupListComponent } from './mapbox/visualization/map-popup/schools-popup-list/schools-popup-list.component';
import { SchoolFormatPipe } from './mapbox/visualization/map-popup/schools-popup-list/school-format.pipe';
import { OriPopupListComponent } from './mapbox/visualization/map-popup/ori-popup-list/ori-popup-list.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { FavoritePersonalizationModalComponent } from './favorites/favorite-personalization-modal/favorite-personalization-modal.component';
import { SortPropertiesPipe } from './mapbox/visualization/map-popup/sort-properties.pipe';
import { RangeTextFormatterPipe } from './mapbox/visualization/colorscale/range-text-formatter.pipe';
import { NumberFormatterPipe } from '../shared/pipes/number-formatter.pipe';
import { FiltersMenuComponent } from './menu/right-menu/layers-menu/filters-menu/filters-menu.component';
import { VotersDataListComponent } from './mapbox/visualization/map-popup/layers-popup-list/voters-data-list/voters-data-list.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { FilterComponent } from './menu/right-menu/layers-menu/filters-menu/filter/filter.component';
import { SliderValues } from './menu/right-menu/layers-menu/filters-menu/filter/slider-values';

const routes: Routes = [
  {
    path: '',
    component: MapComponent,
  },
];

//prettier-ignore
@NgModule({
  declarations: [
    MapboxComponent,
    MapMenuComponent,
    LayersMenuComponent,
    GoogleMapLinkComponent,
    MapComponent,
    ColorScaleComponent,
    PopupHintComponent,
    SelectionToolComponent,
    LevelSelectionComponent,
    ChartLegendGapFixDirective,
    ChartYearSelectionComponent,
    ChartWarningComponent,
    PlotlyChartComponent,
    SpecialHealthCareComponent,
    CommonColorscaleComponent,
    LayerNameFormatPipe,
    FeatureTooltipComponent,
    IsFeatureAccessiblePipe,
    IsGroupAccessiblePipe,
    MenuGroupComponent,
    ChartBlockComponent,
    GroupTitleFormatterPipe,
    FeatureDisclaimerComponent,
    MapSearchComponent,
    MapPopupComponent,
    FormatFeaturePipe,
    LevelsPopupListComponent,
    SchoolsPopupListComponent,
    SchoolFormatPipe,
    OriPopupListComponent,
    FavoritesComponent,
    FavoritePersonalizationModalComponent,
    SortPropertiesPipe,
    RangeTextFormatterPipe,
    FiltersMenuComponent,
    VotersDataListComponent,
    FilterComponent,
    SliderValues,
  ],
  exports: [MapComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PlotlySharedModule,
    PlotlyViaCDNModule,
    NgxSliderModule,
    NgxSliderModule,
    MatRadioModule,
    MatCardModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatTreeModule,
    CdkTreeModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    SharedModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatSliderModule,
    FormsModule,
    HttpClientModule,
    MatProgressBarModule,
    RouterModule.forChild(routes),
    MatCheckboxModule,
  ],
  providers: [
    MapHttpService,
    NumberFormatterPipe,
    {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
  ],
})
export class MapModule {
}
