import { Pipe, PipeTransform } from '@angular/core';
import { FeatureFilter } from '../filters-menu.service';
import {
  LIFE_EXPECTANCY,
  STRUCTURE_BUILT_MEDIAN_YEAR,
} from '../../../../../../shared/types/feature-data-type';

export const STEP = 'STEP';
export const MAX_LIMIT = 'MAX_LIMIT'
export const MIN_LIMIT = 'MIN_LIMIT'

@Pipe({
  name: 'sliderValues',
})
export class SliderValues implements PipeTransform {
  transform(
    featureConst: string,
    filter: FeatureFilter,
    type: string
  ): number | undefined {
    if (type === STEP) {
      if (featureConst === STRUCTURE_BUILT_MEDIAN_YEAR || featureConst === LIFE_EXPECTANCY) {
        return 1;
      }

      return filter.limits.max! !== 0
        ? Math.abs(filter.limits.max!) * 0.05
        : Math.abs(filter.limits.min!) * 0.05;

    } else if (type === MIN_LIMIT) {
      if (featureConst === STRUCTURE_BUILT_MEDIAN_YEAR || featureConst === LIFE_EXPECTANCY) {

        return filter.limits.min! - 1
      }

      return filter.limits.min! - Math.abs(filter.min!) * 0.1 ?? 0;
    } else if (type === MAX_LIMIT) {
      if (featureConst === STRUCTURE_BUILT_MEDIAN_YEAR || featureConst === LIFE_EXPECTANCY) {

        return filter.limits.max! + 1
      }

      return filter.limits.max! + Math.abs(filter.max!) * 0.1 ?? 0
    }

    return
  }
}
