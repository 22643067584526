export const environment = {
  production: false,
  apiUrl: 'https://stage.interactive-map-ai.com/api/',
  domainUrl: 'https://stage.interactive-map-ai.com/',
  mapbox: {
    accessToken: 'pk.eyJ1IjoibWFwLWFpIiwiYSI6ImNsbGdtc2N1azEzaDEzdXF5am05YjI2bXAifQ.oPjs0qvXlsh4QpOi6ymgxg'
  },
  // URL-bounded token
  googleAuthClientId: '1017199604475-rhen3aupu32a892pgkjc5l686m2spb1n.apps.googleusercontent.com',
  SENTRY_AUTH_TOKEN: "20155b74856ab93994b089e6bf06ca3d860b7ccd0f5b4dafee90a921fc1b35bc"
};
