import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ARBITRARY_CELL,
  COUNTY_LEVEL_LAYER,
  LayerStoreService,
  SourceDataStore,
  STATE_LEVEL_LAYER,
} from './services/layer-store.service';
import { CLICK, PopupService } from './services/popup.service';
import { SelectionToolService } from './visualization/selection-tool/selection-tool.service';
import { FeatureFocusService } from '../feature-focus/feature-focus.service';
import { AllLayers } from './base/layers';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { MapBoxService } from './mapbox.service';
import * as mapboxglType from 'mapbox-gl';
import { LngLatBoundsLike } from 'mapbox-gl';
import { MapUrlService } from './services/map-url.service';
import { point } from '@turf/helpers';
import { LayersDataService } from '../menu/right-menu/layers-menu/layers-data.service';
import { environment } from '../../../environments/environment';
import { LoadingService } from './services/loading.service';
import { BreakpointObserverService } from '../../shared/services/breakpoint-observer.service';
import { SelectedCellService } from './services/selected-cell.service';
import {
  limitedCellsResolutionText,
  trialExpiredText,
} from '../../user/map-redirect-modal/map-redirect-modal-text';
import { ModalService } from '../../shared/services/modal.service';
import { UserAccessService } from '../../user/access/user-access.service';
import {
  pointsLayersList,
  PointsService,
} from '../menu/right-menu/layers-menu/points.service';
import {
  crimeRatesList,
  writtenInStateFeatures,
} from '../../shared/types/feature-data-type';
import { ArbitraryCellService } from './services/arbitrary-cell.service';
import booleanIntersects from '@turf/boolean-intersects';
import { SearchGeocoderService } from '../../shared/services/search-geocoder.service';
import { AuthenticationService } from '../../user/authentication.service';
import { Router } from '@angular/router';
import { SubscriptionPlans } from '../../user/user/user.model';
import {
  FeatureFilter,
  FiltersMenuService,
} from '../menu/right-menu/layers-menu/filters-menu/filters-menu.service';
import { MapColoringService } from './visualization/map-coloring.service';

declare let mapboxgl: typeof mapboxglType;

const MAP_FEATURE_LAYERS_OPACITY = 0.45;

@Component({
  selector: 'app-mapbox',
  template: `
    <mat-progress-bar mode="indeterminate"
                      *ngIf="(loadingService.isLoadingManual | async) || (loadingService.isLoadingIntercept | async)"
    ></mat-progress-bar>
    <div class="map-backdrop" *ngIf="accessService.isMapLoadingRestricted || (accessService.isTrialExpired | async)"></div>
    <div id="map" class="map-container"></div>`,
  styleUrls: ['./mapbox.component.scss'],
})
export class MapboxComponent implements OnInit, OnDestroy {

  private isMobile: boolean = false

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  private readonly geoLocateControl = new mapboxgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true
    },
    trackUserLocation: true,
    showUserHeading: true
  })

  private readonly navControl = new mapboxgl.NavigationControl({
    showCompass: false,
  })

  private readonly scaleControl = new mapboxgl.ScaleControl({
    maxWidth: 120,
    unit: 'imperial',
  })

  // map should be initialized after the html container is created,
  // so it is done in ngOnInit instead of constructor which requires adding !
  map!: mapboxgl.Map;

  constructor(
    private layerStore: LayerStoreService,
    private popupService: PopupService,
    private selectionService: SelectionToolService,
    private selectedCellService: SelectedCellService,
    private featureFocusService: FeatureFocusService,
    private mapboxService: MapBoxService,
    private urlService: MapUrlService,
    private layerDataService: LayersDataService,
    public loadingService: LoadingService,
    private breakpointObserverService: BreakpointObserverService,
    // private subscriptionsService: SubscriptionsService,
    private modalService: ModalService,
    public accessService: UserAccessService,
    private pointsService: PointsService,
    private geocoderService: SearchGeocoderService,
    private arbitraryCellService: ArbitraryCellService,
    private authService: AuthenticationService,
    private router: Router,
    private filterService: FiltersMenuService,
    private mapColoring: MapColoringService
  ) {
    (mapboxgl as any).accessToken = environment.mapbox.accessToken;
  }

  ngOnInit() {
    if (this.accessService.getIsUnauthorized() && !this.authService.hasAccessToken() && (this.accessService.isRedRateExceeded.value || this.accessService.getIsClicksLimitExceeded())) {
      this.accessService.showSignInRequiredModal()
    } else if (this.accessService.getIsUnauthorized()) {
      this.accessService.isRedRateExceeded
        .subscribe(isExceeded => {
          // Null and undefined should still be OK
          if (isExceeded) {
            this.router.navigate(['/sign-in'])
          } else if (!isExceeded && !this.map){
            this.buildMap()
          }
        })
    } else if (!this.map) {
      this.buildMap()
    }
  }

  ngOnDestroy() {
    this.filterService.activeFilters.length = 0
    if (this.map) this.map.remove()
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  buildMap() {
    this.map = new mapboxgl.Map(this.layerStore.mapOptions);
    this.accessService.triggerMapLoadedEvent()
    // the _only_ one place where service's map is initialized
    this.mapboxService.map = this.map;

    this.map.on('load', () => {
      this.addSource();
      this.handleMapEvents();

      this.handleSubscriptions();
      this.addControls();

      this.map.dragRotate.disable();
      this.map.touchZoomRotate.disableRotation();
      this.map.touchPitch.disable();

      // Set the max bounds to include the U.S., Puerto Rico, and Hawaii
      const bounds: LngLatBoundsLike = [
        [-180, 5], // Southwest coordinates (longitude, latitude)
        [-40, 73],
      ];
      this.map.setMaxBounds(bounds);


      // Jump to initial location from query params
      this.urlService.initWithParams(this.map)

      this.map.resize();
    });
    // Expose the map instance to the window object for Cypress access
    (window as any).mapObj = this.map
  }

  handleMapEvents(): void {
    const levelsAndOSM = [
      ...this.layerStore.layersLevelList,
      ...pointsLayersList, ARBITRARY_CELL
      //TODO: restore once we have more interaction with OSM
      // ...this.layerStore.OSMLayerList,
    ];

    levelsAndOSM.forEach((layer: string) => {
      this.map.on('click', layer, (e) => {
        const coordinates = e.lngLat;
        const feature = e.features![0];

        this.selectedCellService.unhoverFeature()

        if (!this.accessService.checkAccessIfUnauthorized()) {
          return;
        }

        if (pointsLayersList.includes(feature.layer.id)) {
          this.popupService.popup.remove();
          this.pointsService.handlePointsLayerSelection(feature, this.mapboxService.getFeatureCenter(feature));
          return;
        }

        if (this.map.getLayer(ARBITRARY_CELL)) {
          const arbitraryFeature = this.arbitraryCellService.getArbitraryCellFeature();

          // If ARBITRARY_CELL exists and we click on it, simply detect layer wasnt enough as click does propagate to other layers
          if (booleanIntersects(arbitraryFeature, point([e.lngLat.lng, e.lngLat.lat]))) {
            if (feature.layer.id === ARBITRARY_CELL) {
              this.popupService.popup.remove();
              this.selectedCellService.deselectCell();
              this.arbitraryCellService.handleArbitraryCellClick(e);
              this.selectedCellService.selectCell(feature);
              this.layerDataService.eventDispatcher(e.type, feature);
            }

            return;
          }
        }

        this.selectedCellService.selectCell(feature);

        //todo: fix multiple circle reselection;
        // select multiple by special tool
        this.selectionService.selectAround(coordinates);

        // if (this.subscriptionsService.getIsAllFeaturesAvailable() || !this.layerStore.isCurrentLevelCells()) {
        //   this.layerDataService.eventDispatcher(e.type, feature)
        //
        //   this.popupService.handlePopup(feature, coordinates)
        // } else if (this.subscriptionsService.getIsFeatureAccessible(feature.properties!.external_id)) {
        //   this.layerDataService.eventDispatcher(e.type, feature)
        //
        //   this.popupService.handlePopup(feature, coordinates)
        // } else {
        //   this.modalService.openModal(subscriptionRequiredText)
        // }

        this.layerDataService.eventDispatcher(e.type, feature);

        this.popupService.handlePopup(feature, coordinates, CLICK);
      });
    });

    // this.layerStore.layersLevelList.forEach(layer => {
      // this.map.on('mousemove', layer, (e) => {
      //   const coordinates = e.lngLat;
      //   const feature = e.features![0];
      //
      //   if (this.selectedCellService.isCellSelected() ||
      //       this.selectedCellService.getHoveredFeature()?.id === feature.id) {
      //       return;
      //     }
      //
      //   if (this.breakpointObserverService.isMobile.value) {
      //     // don't mix with click event.
      //     // Otherwise, mobile will show hover popup instead of click popup
      //     return;
      //   }
      //
      //   this.selectedCellService.hoverFeature(feature)
      //   this.popupService.handlePopup(feature, coordinates, HOVER)
      // })
      //
      // this.map.on('mouseleave', layer,() => {
      //   if (this.selectedCellService.isCellSelected()) {
      //     return
      //   }
      //
      //   if (this.breakpointObserverService.isMobile.value) {
      //     // happens right after click on mobile, so don't  remove popup
      //     return;
      //   }
      //
      //   this.popupService.popup.remove()
      //   this.selectedCellService.unhoverFeature()
      // })
    // })

    this.map.on('moveend', (e) => {
      this.layerDataService.eventDispatcher(e.type)

      this.featureFocusService.focusRenderedFeatures(
        this.map,
        this.layerStore.activeLevel.getValue()
      );

      this.urlService.updateUrlParams({
        zoom: Math.round(this.map.getZoom()),
        coordinates: [this.map.getCenter().lat, this.map.getCenter().lng]
      })
    });

    this.map.on('zoomend', () => {
      const zoom = this.map.getZoom();
      this.layerStore.onZoomSwitchLayer(zoom, this.isMobile);
    });

    this.map.on('sourcedata', (e) => {
      this.loadingService.isLoadingManual.next(true)

      if (e.isSourceLoaded) {
        // first, focused newly loaded features so color services depending
        // on focused features can take them for coloring the map
        this.featureFocusService.focusRenderedFeatures(
          this.map,
          this.layerStore.activeLevel.getValue()
        );
        // then dispatch move end to trigger map re-coloring
        this.layerDataService.eventDispatcher('moveend')
      }
    })

    this.map.on('idle', () => {
      this.loadingService.isLoadingManual.next(false)
      this.map.resize();
    });
  }

  addControls(): void {
    if (!this.isMobile) {
      if (!this.map.hasControl(this.navControl)) {
        this.map.addControl(this.navControl, 'bottom-right');
      }

      if (!this.map.hasControl(this.scaleControl)) {
        this.map.addControl(this.scaleControl);
      }
    }

    if (!this.map.hasControl(this.geoLocateControl)) {
      this.map.addControl(this.geoLocateControl, "bottom-right");
    }

    //#geocoder-container is located in index.html body, it needed to make it invisible
    this.geocoderService.reverseGeocoder.addTo("#geocoder-container")
  }

  private addSource(): void {
    const sourceData: SourceDataStore = this.layerStore.sourceDataStore;

    this.connectSources(sourceData, 'levels')
    this.connectSources(sourceData, 'osm')
    this.connectSources(sourceData, 'crime')
    this.connectSources(sourceData, 'schools')
  }

  private connectSources(sourceData: SourceDataStore, type: string): void {
    Object.keys(sourceData[type]).forEach((el) => {
      const sourceId = sourceData[type][el as keyof typeof sourceData[typeof type]].type
      const minzoom = sourceData[type][el as keyof typeof sourceData[typeof type]].minzoom
      const isSourceAlreadyExists = !this.map.getSource(sourceId)
      const isLayerAlreadyExists = !this.map.getLayer(sourceId)

      if (isSourceAlreadyExists) {
        this.map.addSource(sourceId, {
          type: 'vector',
          promoteId: 'id',
          url:
            environment.domainUrl +
            sourceData[type][el as keyof typeof sourceData[typeof type]].subdir +
            '.json',
        });
      }

      if (isLayerAlreadyExists) {
        if (type === 'levels') {
          this.map.addLayer({
            id: sourceId,
            type: 'fill',
            source: sourceId,
            'source-layer': sourceId,
            maxzoom: 24,
            minzoom: 0 || minzoom,
            paint: {
              'fill-opacity': MAP_FEATURE_LAYERS_OPACITY,
              'fill-color': '#F5F5F5', // appears on first map loading and layer switching
            },
            // Somehow ternary operator doesn't work here, so I had to duplicate this piece of code
            filter: ['>', ['to-number', ['get', 'population']], 1],
          });
        } else if (type === 'osm') {
          this.map.addLayer({
            id: sourceId,
            type: 'fill',
            source: sourceId,
            'source-layer': sourceId,
            maxzoom: 24,
            minzoom: 0 || minzoom,
            paint: {
              'fill-opacity': 0.4,
            },
          });
        }
      }

    });

    if (type === 'crime' || type === 'schools') {
      this.pointsService.addPointsLayers(sourceData, type, this.map)
    }
  }

  private handleSubscriptions(): void {
    this.layerStore.activeLayer
      .pipe(debounceTime(50), takeUntil(this.destroy$))
      .subscribe((activeLayerChange) => {
        const focusedFeatures = this.featureFocusService.currentFocusedFeatures();
        this.rerenderColorScale(
          this.layerStore.activeLevel.getValue(),
          activeLayerChange,
          focusedFeatures.ofType(activeLayerChange).values(),
          focusedFeatures.isBeingLoaded()
        );
      });

    this.featureFocusService.focusedFeatures
      .pipe(debounceTime(100), takeUntil(this.destroy$))
      .subscribe((focusedFeatures) => {

        this.rerenderColorScale(
          this.layerStore.activeLevel.getValue(),
          this.layerStore.activeLayer.getValue(),
          focusedFeatures
            .ofType(this.layerStore.activeLayer.getValue())
            .values(),
          focusedFeatures.isBeingLoaded()
        );

        // const isLevelLoaded = this.map.isSourceLoaded(this.layerStore.activeLevel.getValue())

        // if (this.accessService.getIsTrial() && this.layerStore.isCurrentLevelCells() && isLevelLoaded) {
        //   this.modalService.handleLimitedAccessModal(focusedFeatures, this.map.getZoom())
        // }
      });

    this.layerStore.activeLevel
      .pipe(takeUntil(this.destroy$)).subscribe((level) => {
      this.changeActiveLevel(level);
    });

    this.accessService.isTrialExpired.subscribe(status => {
      const path = this.urlService.getUrl()
      if (status && (path === '' || path === '/')) {
        this.modalService.openModal(trialExpiredText)
      }
    })

    this.breakpointObserverService.isMobile.subscribe(mobile => {
      this.isMobile = mobile
    })

    this.filterService.updateFiltersSubject
      .pipe(debounceTime(150))
      .subscribe(() => {
        const focusedFeatures = this.featureFocusService.currentFocusedFeatures();
        this.rerenderColorScale(
          this.layerStore.activeLevel.getValue(),
          this.layerStore.activeLayer.getValue(),
          focusedFeatures.ofType(this.layerStore.activeLayer.getValue()).values(),
          focusedFeatures.isBeingLoaded()
        );
    })
  }

  private isBeingLoadedAnimation = true;

  private rerenderColorScale(
    level: string,
    layer: string,
    values: number[],
    isBeingLoaded: boolean = true
  ): void {
    const currentUserPlan = SubscriptionPlans.description[this.accessService.getUserPlan() as SubscriptionPlans]

    const nullValueColor = '#888'

    if (!this.accessService.getIsTrial() && currentUserPlan && !currentUserPlan.accessibleLevels.includes(level)) {
      this.map.setZoom(5.99)
      this.modalService.openModal(limitedCellsResolutionText)
      this.layerStore.handleLevelChange(COUNTY_LEVEL_LAYER)
      return
    }

    const uniqueValues = [...new Set(values)]

    this.isBeingLoadedAnimation = isBeingLoaded;
    if (isBeingLoaded) {
      this.animateLoading(level);
      return
    } else {
      this.map.setPaintProperty(level, 'fill-opacity', MAP_FEATURE_LAYERS_OPACITY)
    }

    if (uniqueValues.length === 1 && uniqueValues[0] === null || crimeRatesList.includes(layer) && this.layerStore.activeLevel.value === STATE_LEVEL_LAYER) {
      this.map.setPaintProperty(level, 'fill-color', nullValueColor); // Apply the grey color for all-null state
      return;
    }

    if (uniqueValues.length <= 1) {
      return;
    }

    const colorScaleUnsafe =  Object.values(AllLayers.description).find(
      (el) => el.layers.includes(layer))

    if(colorScaleUnsafe === undefined) {
      console.error(`Color scale for layer ${layer} is not defined`)
    }
    const colorScale = colorScaleUnsafe!.colorScale!;

    //checks if values array contains at least single truthy value
    if (!uniqueValues.some(Boolean)) return;

    if (this.filterService.activeFilters.length > 0) {
      this.useFeatureFilter(level, layer, values, colorScale)
      return;
    }

    // Construct the common part of the fill-color expression
    const commonFillColorExpression = [
      ['boolean', ['feature-state', FeatureFocusService.focusedFeatureState], false],
      colorScale.toThresholdColorExpression(uniqueValues, layer, level),
      nullValueColor,
    ];

    const ifNullExpression = writtenInStateFeatures.includes(layer) ?
      // For layers that depend on feature-state as we cannot check if property EXIST and is NULL in feature-state
      ['==', ['feature-state', layer], null] :
      ['any', ['!', ['has', layer]], ['==', ['get', layer], null]]; // For layers from tile server

    // const trialFeaturesExpression = [
    //   'case',
    //   ifNullExpression, nullValueColor,
    //   [
    //     'all',
    //     ['boolean', ['feature-state', FeatureFocusService.focusedFeatureState], true],
    //     ['in', ['get', 'external_id'], ['literal', this.subscriptionsService.getAccessibleFeatures().features]]
    //   ],
    //   colorScale.toThresholdColorExpression(uniqueValues, layer),
    //   nullValueColor
    // ];

    // const isAllAccessibleOrNotCurrentCells = this.subscriptionsService.getAccessibleFeatures().state === AccessibleFeatureState.ALL || !this.layerStore.isCurrentLevelCells();

    // this.map.setPaintProperty(level, 'fill-color', isAllAccessibleOrNotCurrentCells ?
    //   [
    //     'case',
    //     ifNullExpression, nullValueColor, // Handle ifNull condition based on feature-state or layer existence
    //     ...commonFillColorExpression, // Apply common fill-color expressions
    //   ] :
    //   trialFeaturesExpression // Apply the distinct logic for trial
    // );

    this.map.setPaintProperty(level, 'fill-color',
      [
        'case',
        ifNullExpression, nullValueColor, // Handle ifNull condition based on feature-state or layer existence
        ...commonFillColorExpression, // Apply common fill-color expressions
      ]
    );
  }

  private useFeatureFilter(level: string, layer: string, values: number[], colorScale: any): void {
    const relevantFilters: FeatureFilter[] = this.filterService.activeFilters

      relevantFilters.forEach(filter => {
        const layer = filter.featureConst

        if (filter.min === undefined || filter.max === undefined || !isFinite(filter.min) || !isFinite(filter.max)) {
          // Get actual values from features
          const featureValues = this.map
            .queryRenderedFeatures(undefined, {
              layers: [this.layerStore.activeLevel.getValue()],
            })
            .map((feature) => {
              return (
                feature.state?.[layer] ??
                feature.properties?.[layer]
              );
            })
            .filter((value) => value !== undefined && value !== null);

          if (featureValues.length === 0) {
            this.layerDataService.eventDispatcher('moveend', undefined, layer)
          }

          const maxVal = Math.max(...featureValues)
          const minVal = Math.min(...featureValues)

          filter.min = minVal ?? 0;
          filter.max = maxVal ?? 0;

          filter.limits.min = minVal
          filter.limits.max = maxVal

          this.filterService.updateFilters()
        }
      });

      // Construct filter conditions for all relevant filters
      const filterConditions = relevantFilters.map(filter => ([
        ['>=', ['coalesce', ['feature-state', filter.featureConst], ['get', filter.featureConst]], filter.min],
        ['<=', ['coalesce', ['feature-state', filter.featureConst], ['get', filter.featureConst]], filter.max]
      ])).flat();

      // Construct null check conditions for each filter
      const nullConditions = relevantFilters.map(filter => (
        writtenInStateFeatures.includes(filter.featureConst) ?
          ['==', ['feature-state', filter.featureConst], null] :
          ['any', ['!', ['has', filter.featureConst]], ['==', ['get', filter.featureConst], null]]
      ));

      // Filters are used in "all" expression as we only show what matches them all
      const combinedFilterConditions = ['all', ...filterConditions];

      // Use "any" for null checks
      const combinedNullConditions = ['any', ...nullConditions];

      const filterCaseExpression = [
        'case',
        combinedNullConditions, '#888',
        combinedFilterConditions, colorScale.toThresholdColorExpression(values, layer, level),
        '#888'
      ];
      this.map.setPaintProperty(level, 'fill-color', filterCaseExpression);
      return;
  }


  private changeActiveLevel(currentLevel: string) {

    this.layerStore.layersLevelList.forEach((el) => {
      this.map.setLayoutProperty(el, 'visibility', 'none');
    });
    this.map.setLayoutProperty(
      currentLevel,
      'visibility',
      'visible'
    );
  }

  private animateLoading(level: string, time = 0): void {
    if(!this.isBeingLoadedAnimation) {
      return;
    }

    const duration = 4000;  // Duration of a full oscillation
    // Oscillates between 0 and 1
    const waveProgress = (time % duration) / duration;
    const factor = (Math.sin(waveProgress * Math.PI * 2) + 1) / 2;
    this.map.setPaintProperty(level, 'fill-opacity', factor * MAP_FEATURE_LAYERS_OPACITY + 0.2)
    window.requestAnimationFrame((time) => this.animateLoading(level, time));
  }
}
